import React from 'react'

export const Footer = (props) => {
    return (
      <div>
        <div id='footer'>
            <div className='container text-center'>
            {/* <p>
            Copyright © {new Date().getFullYear()} Dr. Dierickx |  Hosted by{' '}
                <a href={props.data ? props.data.hostedbyURL : 'loading'} rel='nofollow'>
                {props.data ? props.data.hostedby : 'loading'}
                </a>
            </p> */}
            <p>
            Copyright © {new Date().getFullYear()} Dr. Dierickx
            </p>
            </div>
        </div>
      </div>
    );
  };
  