import React from 'react'

export const Wachtdiensten = (props) => {
    return (
      <div id="wachtdiensten">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              {" "}
              <img src="img/waiting-cat-1024x576.jpg" className="img-responsive" alt="" />{" "}
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h2>Wachtdiensten</h2>
                {/* <p>{props.data ? props.data.paragraph : "loading..."}</p> */}
                <p>De WEEKWACHT is dagelijks van 19.00 tot 08.00 de volgende morgen.<br></br>De WEEKENDWACHT gaat in op vrijdag om 19.00 en duurt tot maandag 08.00.<br></br>
                Het nieuwe wachtnummer is <br></br><br></br><h3><i className='fa fa-phone'></i>{' '}1733</h3></p>
                {/* <h3>Why Choose Us?</h3>
                <div className="list-style">
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {props.data
                        ? props.data.Why.map((d, i) => (
                            <li key={`${d}-${i}`}>{d}</li>
                          ))
                        : "loading"}
                    </ul>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {props.data
                        ? props.data.Why2.map((d, i) => (
                            <li key={`${d}-${i}`}> {d}</li>
                          ))
                        : "loading"}
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  