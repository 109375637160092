import React from 'react'

export const Info = (props) => {
    return (
      <div id='info' className='text-center'>
        <div className='container'>
          <div className='col-md-8 col-md-offset-2 section-title'>
            <h2>Nuttige info</h2>
          </div>
          <div id='row'>

          <table class="table no-border">
                <tbody>
            {props.data
              ? props.data.map((d, i) => (
                <tr>
                    <th scope="row">{d.naam}</th>
                    <td>{d.telefoonnummer}</td>
                    <td><a href={d.URL}>{d.URL}</a></td>
                </tr>
                ))
                : 'loading'}
                </tbody>
                </table>
          </div>
        </div>
      </div>
    )
  }
  