import React, { useState } from 'react'
// import emailjs from 'emailjs-com'
import axios from 'axios';

const initialState = {
  TheName: '',
  TheEmail: '',
  TheMessage: '',
  mailSent: false,
  error: null
}

export const Contact = (props) => {
  // const [{ name, email, message }, setState] = useState(initialState)
  const [{ TheName, TheEmail, TheMessage, mailSent }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  const clearState = () => setState({ ...initialState })

  // const [isHovering, setIsHovering] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(TheName, TheEmail, TheMessage)
    const API_PATH = 'https://www.dokterdierickx.be/contact/index.php';

    axios({
      method: 'post',
      url: `${API_PATH}`,
      headers: { 'content-type': 'application/json' },
      data: { TheName, TheEmail, TheMessage }
    })
      .then(result => {
        // setState({ mailSent: result.data.sent })
        // mailSent.setState(result.data.sent)
        // this.setState({ message: "Updated Content!"});
        clearState()
        setState({ mailSent: true })
        // setIsHovering(true);
      })
      .catch(error => setState({ error: error.message }));

    // emailjs
    //   .sendForm(
    //     'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID'
    //   )
    //   .then(
    //     (result) => {
    //       console.log(result.text)
    //       clearState()
    //     },
    //     (error) => {
    //       console.log(error.text)
    //     }
    //   )
  }

  function IntroGreeting(props) {
    return 'Gelieve onderstaand formulier in te vullen om ons een email te sturen en ik contacteer u zo spoedig mogelijk.';
  }
  
  function VerzondenGreeting(props) {
    return 'Je boodschap is verzonden!';
  }

  function Greeting() {
    // const Verzonden = props.mailSent;
    if (mailSent) {
      return <VerzondenGreeting />;
    }
    return <IntroGreeting />;
  }


  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Contact</h2>
                <p>
                <Greeting />
                </p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>{!mailSent &&
                      <input
                        type='text'
                        id='TheName'
                        name='TheName'
                        className='form-control'
                        placeholder='Naam'
                        value={TheName}
                        required
                        onChange={handleChange}
                      />}
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>{!mailSent &&
                      <input
                        type='email'
                        id='TheEmail'
                        name='TheEmail'
                        className='form-control'
                        placeholder='Email adres'
                        value={TheEmail}
                        required
                        onChange={handleChange}
                      />}
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>{!mailSent &&
                  <textarea
                    name='TheMessage'
                    id='TheMessage'
                    className='form-control'
                    rows='4'
                    placeholder='Typ hier je bericht'
                    value={TheMessage}
                    required
                    onChange={handleChange}
                  ></textarea>}
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>{!mailSent &&
                <button type='submit' className='btn btn-custom btn-lg'>
                  Bericht verzenden
                </button>}
              </form>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Adres
                </span>
                {props.data ? props.data.address : 'loading'}
                <br />
                {props.data ? props.data.city : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Telefoon
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
          <div className='col-md-12'>

          </div>
        </div>
      </div>
      {/* <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2022 Dr. Dierickx  |  Hosted by{' '}
            <a href='https://itmeadows.com' rel='nofollow'>
              IT Meadows
            </a>
          </p>
        </div>
      </div> */}
    </div>
  )
}
