import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Actueel } from "./components/actueel";
import { Raadplegingen } from "./components/raadplegingen";
// import { About } from "./components/about";
import { Wachtdiensten } from "./components/wachtdiensten";
import { Huisbezoeken } from "./components/huisbezoeken";
import { Info } from "./components/info";
// import { Gallery } from "./components/gallery";
// import { Team } from "./components/Team";
// import { Testimonials } from "./components/testimonials";
// import MapSection from './components/googlemaps'
import { Contact } from "./components/contact";
import { Footer } from "./components/footer";
// import { Meeltje } from "./components/contactsmtp";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";

import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

// const location = {
//   address: 'Steenakkerstraat 7, 9070 Destelbergen',
//   lat: 1.029555964289344,
//   lng: 3.798584714394942,
// } // our location object from earlier


const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />

      <Header data={landingPageData.Header} />
      {/* <Features data={landingPageData.Features} /> */}
      <Raadplegingen data={landingPageData.Raadplegingen} />
      <Huisbezoeken data={landingPageData.Services} />
      {/* <Gallery data={landingPageData.Gallery}/>

      <Team data={landingPageData.Team} /> */}
      {/* <Contact data={landingPageData.Contact} /> */}
      {/* <About data={landingPageData.About} /> */}
      <Wachtdiensten data={landingPageData.Wachtdiensten} />
      <Actueel data={landingPageData.Actueel} />
      <Info data={landingPageData.Info} />
      <Contact data={landingPageData.Contact} />
      {/* <MapSection location={location} zoomLevel={18} /> */}
      <Footer data={landingPageData.Footer} />
    </div>
  );
};

export default App;
