import React from 'react'

export const Raadplegingen = (props) => {
    return (
      <div id='raadplegingen' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>Raadplegingen</h2>
        </div>
            <div className='row'>
                <table class="table">
                {/* <caption>List of users</caption> */}
                <thead>
                    <tr>
                    <th scope="col"></th>
                    <th scope="col">Voormiddag</th>
                    <th scope="col">Namiddag</th>
                    </tr>
                </thead>
                <tbody>
                {props.data
              ? props.data.map((d, i) => (
                <tr>
                    <th scope="row">{d.Dag}</th>
                    <td>{d.Voormiddag}</td>
                    <td>{d.Namiddag}</td>
                </tr>
                ))
                : 'loading'}



                {/* <tr>
                    <th scope="row">Maandag</th>
                    <td>8:15 - 10:00</td>
                    <td>17:30 - 19:00</td>
                    </tr>
                    <tr>
                    <th scope="row">Dinsdag</th>
                    <td>8:15 - 10:00</td>
                    <td>17:30 - 19:00</td>
                    </tr>
                    <tr>
                    <th scope="row">Woensdag</th>
                    <td>-</td>
                    <td>17:30 - 19:00</td>
                    </tr>
                    <tr>
                    <th scope="row">Donderdag</th>
                    <td>8:15 - 10:00</td>
                    <td>-</td>
                    </tr>
                    <tr>
                    <th scope="row">Vrijdag</th>
                    <td>8:30 - 10:00</td>
                    <td>17:30 - 19:00</td>
                    </tr>
                    <tr>
                    <th scope="row">Zaterdag</th>
                    <td>Op afspraak</td>
                    <td>-</td>
                    </tr> */}



                </tbody>
                </table>
            </div>
        </div>
      </div>
  )
}
