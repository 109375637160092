import React from 'react'

export const Huisbezoeken = (props) => {
  return (
    <div id='huisbezoeken' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Huisbezoeken</h2>
          <p>
          Deze kunnen altijd worden aangevraagd op nummer
          <br /><br />
          09 232 08 13
          <br /><br />
          Bij voorkeur aan te vragen voor 10u00.
          </p>
        </div>
        {/* <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                  {' '}
                  <i className={d.icon}></i>
                  <div className='service-desc'>
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : 'loading'}
        </div> */}
      </div>
    </div>
  )
}
