import React from 'react'

export const Actueel = (props) => {
    return (
      <div id='actueel' className='text-center'>
        <div className='container'>
          <div className='col-md-10 col-md-offset-1 section-title'>
            <h2>Actueel</h2>
          </div>
          <div className='row'>
  
                  <div key='dsf5d' className='col-md-8 col-md-offset-2 intro-text'>
                    {' '}
                    <i className={props.data ? props.data.icon : 'loading'}></i>
                    <h3>{props.data ? props.data.title : 'loading'}</h3>
                    <p>{props.data ? props.data.text : 'loading'}</p>
                    <p>{props.data ? props.data.text2 : 'loading'}</p>
                  </div>
  
          </div>
        </div>
      </div>
    )
  }
  